import { useQuery } from "@tanstack/react-query";
import { getAvatars } from ".";

export const useGetAvatars = () => {
  return useQuery({
    queryKey: ["fetchAvatars"],
    queryFn: () => getAvatars(),
    select: (data) =>
      data?.map((avatar) => {
        if (avatar.rarity === "silver") {
          avatar.rarity = "common";
        }
        return avatar;
      }),
    staleTime: 1000 * 60 * 5, // 5 minutes
  });
};
