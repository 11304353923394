import { useGetUserProfile } from "@portal-frontend-ssr/blast-api";
import { useEffect } from "react";
import { useAuth } from "../AuthProvider";

export const useGetCurrentUserProfile = () => {
  const auth = useAuth();

  const query = useGetUserProfile(auth.userId, auth.initialUserProfile);

  // Redirect to sign-up page if a user is authenticated but doesn't have a profile
  useEffect(() => {
    if (!auth.userId || query.isFetching || query.data === undefined) {
      return;
    }

    if (query.data === null) {
      window.location.href = "/sign-up";
    }
  }, [auth.userId, query.isFetching, query.data]);

  return query;
};
