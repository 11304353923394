import { ReactNode, createContext, useState, useContext } from "react";
import { AvatarModal } from ".";

interface AvatarModalProviderValue {
  openModal: () => void;
  closeModal: () => void;
}

export const ChangeAvatarModalContext = createContext<AvatarModalProviderValue | undefined>(undefined);

export const AvatarModalProvider = ({ children }: { children: ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <ChangeAvatarModalContext.Provider
      value={{
        openModal: () => setIsOpen(true),
        closeModal: () => setIsOpen(false),
      }}
    >
      <AvatarModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
      {children}
    </ChangeAvatarModalContext.Provider>
  );
};

export const useAvatarModal = () => {
  const context = useContext(ChangeAvatarModalContext);

  if (!context) {
    throw new Error("useAvatarModal must be used within a AvatarModalProvider");
  }

  return context;
};
