import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../queryKeys";
import { UserProfile } from "../schemas";
import { getUserProfile } from ".";

export const useGetUserProfile = (userId?: string, initialData?: UserProfile) => {
  return useQuery({
    queryKey: queryKeys.profile(userId, initialData),
    queryFn: userId ? () => getUserProfile(userId) : undefined,
    enabled: Boolean(userId),
    retry: false,
    staleTime: 1000 * 60,
    initialData: initialData?.id === userId ? initialData : undefined,
  });
};
