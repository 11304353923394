import { RegisterRequest } from "../shared/types";
import { blastApi } from "../../blastApi";
import { AxiosError } from "axios";

export type RegisterResult = "success" | "email-already-registered" | "invalid-password" | "invalid-email";

/*
 * Register a new user.
 */
export const register = async ({
  email,
  password,
  isOptIn,
  recaptchaToken,
}: RegisterRequest): Promise<RegisterResult> => {
  try {
    await blastApi.post(
      "/v1/auth/register",
      {
        email,
        password,
        recaptchaToken,
        newsSubscription: isOptIn,
      },
      { withCredentials: true },
    );

    return "success";
  } catch (error) {
    if (error instanceof AxiosError) {
      // eslint-disable-next-line
      const code = error.response?.data?.code;
      if (code === "email-already-taken") {
        return "email-already-registered";
      } else if (code === "invalid-password") {
        return "invalid-password";
      } else if (code === "invalid-email") {
        return "invalid-email";
      }
    }

    throw error;
  }
};
