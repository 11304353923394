import { LoginRequest } from "../shared/types";
import { blastApi } from "../../blastApi";
import { AxiosError } from "axios";

export type LoginResult = "success" | "wrong-credentials" | "not-verified" | "user-third-party-register";
/*
 * Login the user by sending a POST request to the login endpoint, with the login and password
 */
export const login = async (request: LoginRequest): Promise<LoginResult> => {
  try {
    await blastApi.post(
      "/v1/auth/login",
      {
        login: request.login,
        password: request.password,
      },
      {
        withCredentials: true,
      },
    );

    return "success";
  } catch (error) {
    if (error instanceof AxiosError) {
      // eslint-disable-next-line
      const code = error.response?.data?.code;
      if (code === "user-not-verified") {
        return "not-verified";
      } else if (code === "wrong-credentials") {
        return "wrong-credentials";
      } else if (code === "user-third-party-register") {
        return "user-third-party-register";
      }
    }

    throw error;
  }
};
