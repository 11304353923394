import { blastApi } from "../../blastApi";

/*
 * Logout the user by revoking the refresh token
 */
export const logout = async () => {
  await blastApi.post(
    "/v1/auth/logout",
    {},
    {
      withCredentials: true,
    },
  );
};
