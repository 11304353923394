import { QueryClient } from "@tanstack/react-query";

export const queryKeys = {
  all: ["users"] as const,
  profile: (id?: string, initialUser?: object) => [...queryKeys.all, "profile", id, initialUser] as const,
};

export const invalidateUserProfile = (queryClient: QueryClient, userId?: string) => {
  return queryClient.invalidateQueries({
    predicate: (query) => {
      if (query.queryKey.includes("profile") && query.queryKey.includes(userId)) {
        return true;
      }
      return false;
    },
  });
};
