import cookies from "js-cookie";
import { getDomain } from "../getDomain";

interface AccessTokenJson {
  exp: string;
  username: string;
}

export const readUserIdFromCookies = () => {
  const accessTokenJson = readAccessTokenJson();
  return accessTokenJson?.username;
};

export const readAccessTokenJson = (): AccessTokenJson | undefined => {
  const accessToken = readAccessTokenFromCookie();
  if (accessToken === undefined) {
    return undefined;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return JSON.parse(window.atob(decodeURIComponent(accessToken.split(".")[1])));
};

export const readAccessTokenFromCookie = () => {
  return cookies.get("access_token");
};

const REGISTRATION_EMAIL_COOKIE = "blasttv-registration-email";

export const setRegistrationEmailCookie = (email: string) => {
  cookies.set(REGISTRATION_EMAIL_COOKIE, email, {
    expires: 1,
    domain: `.${getDomain()}`,
  });
};
