import { blastApi } from "../../blastApi";
import { UserProfile } from "../schemas";

export const updateUserProfile = async (
  userId: string,
  body: Partial<
    Pick<
      UserProfile,
      | "id"
      | "gender"
      | "country"
      | "optIn"
      | "favoriteTeams"
      | "avatarId"
      | "highContrastMode"
      | "preferredGames"
      | "steamId"
    >
  >,
) => {
  await blastApi.patch(`/v1/users/${userId}/profile`, body);
};
