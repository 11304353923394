import { useGetAvatars, generateImageUrl, generateAssetsId } from "@portal-frontend-ssr/blast-assets-api";
import { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { LockClosedIcon } from "@heroicons/react/24/outline";
import { useUpdateUserProfile } from "@portal-frontend-ssr/blast-api";
import { Tooltip, Modal } from "@portal-frontend-ssr/ui";
import { useGetCurrentUserProfile } from "../../hooks/useGetCurrentUserProfile";
import backgroundSrc from "./assets/background.jpg";

export const AvatarModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const { data: userProfile } = useGetCurrentUserProfile();
  const { mutate: updateAvatar } = useUpdateUserProfile({
    userId: userProfile?.id,
    onSuccess: onClose,
  });
  const [selectedAvatarId, setSelectedAvatarId] = useState<string | undefined>(userProfile?.avatarId);

  useEffect(() => {
    setSelectedAvatarId(userProfile?.avatarId);
  }, [userProfile?.avatarId]);

  const avatarSrc = useMemo(() => {
    if (!selectedAvatarId) return undefined;
    return generateImageUrl("avatars", generateAssetsId(selectedAvatarId, "3d"), {
      format: "auto",
      height: "256",
    });
  }, [selectedAvatarId]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="!w-full max-w-4xl">
      <div
        style={{
          backgroundImage: `url(${backgroundSrc})`,
        }}
        className="rounded-small flex w-full flex-col items-center gap-6 p-12 max-md:gap-4 max-md:p-8"
      >
        <h1 className="font-style-heading-h2">Change avatar</h1>
        <div className="flex size-48 flex-col items-center justify-center rounded-full bg-white/10 max-md:size-32">
          {avatarSrc ? (
            <img src={avatarSrc} alt="avatar" className="rounded-full" />
          ) : (
            <h2 className="font-style-title-t1">?</h2>
          )}
        </div>
        <AvatarsSection onSelect={setSelectedAvatarId} selectedAvatarId={selectedAvatarId} />
        <div className="flex w-full items-center justify-center gap-4">
          <button className="button button-secondary max-w-36 flex-1" onClick={onClose}>
            Cancel
          </button>
          <button
            className="button max-w-36 flex-1"
            disabled={!selectedAvatarId}
            onClick={
              selectedAvatarId
                ? () =>
                    updateAvatar({
                      avatarId: selectedAvatarId,
                    })
                : undefined
            }
          >
            Save
          </button>
        </div>
      </div>
    </Modal>
  );
};

const AvatarsSection = ({
  onSelect,
  selectedAvatarId,
}: {
  onSelect: (avatarId: string) => void;
  selectedAvatarId?: string;
}) => {
  const { data: avatars, isLoading: isLoadingAvatars } = useGetAvatars();
  const { data: userProfile } = useGetCurrentUserProfile();

  const sortedAvatars = useMemo(() => avatars?.sort((a, b) => a.orderIndex - b.orderIndex) ?? [], [avatars]);

  const unlockedAvatars = useMemo(() => {
    return sortedAvatars.filter((avatar) => userProfile?.avatars?.includes(avatar.id) && avatar.isUnlockOnly);
  }, [sortedAvatars, userProfile]);

  const publicAndLockedAvatars = useMemo(
    () => sortedAvatars.filter((avatar) => !avatar.hidden && !userProfile?.avatars?.includes(avatar.id)),
    [sortedAvatars, userProfile],
  );

  return (
    <div className="bg-background-95 scrollbar-thin grid w-full grid-cols-6 gap-6 overflow-y-scroll p-6 max-md:max-h-[40vh] max-md:grid-cols-3 max-md:gap-4 max-md:p-4 md:max-h-80">
      {isLoadingAvatars ? (
        Array.from({ length: 18 }).map((_, index) => (
          <div key={index} className="aspect-square w-full">
            <div className="bg-background-80 m-auto size-full animate-pulse rounded-full" />
          </div>
        ))
      ) : (
        <>
          {publicAndLockedAvatars.map((avatar) => (
            <div key={avatar.id} className="aspect-square w-full">
              <AvatarButton
                avatar={avatar}
                isLocked={avatar.isUnlockOnly}
                onClick={onSelect}
                isChosen={avatar.id === selectedAvatarId}
              />
            </div>
          ))}
          {unlockedAvatars.length > 0 && (
            <>
              <div className="col-span-6 flex items-center gap-4 max-md:col-span-3">
                <div className="bg-background-80 h-px flex-1" />
                <div className="font-style-label-l2">Rewards</div>
                <div className="bg-background-80 h-px flex-1" />
              </div>
              {unlockedAvatars.map((avatar) => (
                <div key={avatar.id} className="aspect-square w-full">
                  <AvatarButton avatar={avatar} onClick={onSelect} isChosen={avatar.id === selectedAvatarId} />
                </div>
              ))}
            </>
          )}
        </>
      )}
    </div>
  );
};

const AvatarButton = ({
  avatar,
  isLocked,
  isChosen,
  onClick,
}: {
  avatar: { id: string; name: string; isUnlockOnly?: boolean };
  isLocked?: boolean;
  isChosen?: boolean;
  onClick: (avatarId: string) => void;
}) => {
  return (
    <Tooltip content={isLocked ? `${avatar.name} (Unlocked as a Reward)` : avatar.name} enterDelay={500}>
      <button
        className={classNames("rounded-small relative size-full transition-all duration-150 ease-in-out", {
          "cursor-default opacity-30": isLocked, // Tooltip does not work if button is disabled
          "bg-white/20": isChosen,
        })}
        onClick={isLocked ? undefined : () => onClick(avatar.id)}
      >
        <img
          src={generateImageUrl("avatars", generateAssetsId(avatar.id, "3d"), {
            format: "auto",
            height: "164",
          })}
          alt="avatar"
          className="m-auto rounded-full"
        />
        {isLocked ? <LockClosedIcon className="absolute bottom-2.5 right-0 size-4" /> : null}
      </button>
    </Tooltip>
  );
};
