import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateUserProfile } from ".";
import { invalidateUserProfile } from "../queryKeys";

export const useUpdateUserProfile = ({ userId, onSuccess }: { userId?: string; onSuccess?: () => void }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: userId
      ? (body: Parameters<typeof updateUserProfile>[1]) => {
          return updateUserProfile(userId, body);
        }
      : undefined,
    onSuccess: () => {
      void invalidateUserProfile(queryClient, userId);

      onSuccess?.();
    },
  });
};
