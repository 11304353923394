import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from "react";
import {
  useLogin,
  useLogout,
  useRegister,
  useRequestResetPassword,
  useResendVerificationEmail,
  LoginRequest,
  RegisterRequest,
  LoginResult,
  RegisterResult,
} from "@portal-frontend-ssr/blast-api";
import { UserProfile } from "@portal-frontend-ssr/blast-api";
import { getConfig } from "@portal-frontend-ssr/config";
import { readUserIdFromCookies, setRegistrationEmailCookie } from "./helpers/cookies";
import { clearSessionCookies } from "./helpers/clearSessionCookies";

export interface AuthProviderValue {
  isAuthenticated: boolean;
  logout: () => Promise<void>;
  login: (credentials: LoginRequest) => Promise<LoginResult>;
  register: (credentials: RegisterRequest) => Promise<RegisterResult>;
  requestResetPassword: (args: { email: string }) => Promise<void>;
  resendVerificationEmail: (args: { email: string }) => Promise<void>;
  userId?: string;
  initialUserProfile?: UserProfile;
}

export const AuthContext = createContext<AuthProviderValue | null>(null);

export function AuthProvider({
  userId: initialUserId,
  userProfile: initialUserProfile,
  children,
}: {
  userId?: string;
  userProfile: UserProfile | null;
  children?: ReactNode;
}) {
  const [userId, setUserId] = useState(initialUserId);
  const { mutateAsync: logoutAction } = useLogout({
    onSuccess: () => {
      clearSessionCookies();
    },
  });
  const logout = useCallback(async () => {
    const currentUserId = readUserIdFromCookies();
    await logoutAction();

    if (currentUserId && /google|apple/i.test(currentUserId)) {
      window.location.assign(`${getConfig().API_BASE_URL}/v1/auth/providers/logout`);
      return;
    }

    setUserId(undefined);
  }, [logoutAction]);

  const { mutateAsync: handleLogin } = useLogin();
  const login = useCallback(
    async (credentials: LoginRequest) =>
      handleLogin(credentials).then((result) => {
        if (result === "success") {
          setUserId(readUserIdFromCookies());
        } else if (result === "not-verified") {
          setUserId(readUserIdFromCookies());
          setRegistrationEmailCookie(credentials.login);
        }
        return result;
      }),
    [handleLogin],
  );

  const { mutateAsync: handleRegister } = useRegister();
  const register = useCallback(
    async (credentials: RegisterRequest) =>
      handleRegister(credentials).then((result) => {
        if (result === "success") {
          setUserId(readUserIdFromCookies());
          setRegistrationEmailCookie(credentials.email);
        }
        return result;
      }),
    [handleRegister],
  );

  const { mutateAsync: handleRequestResetPassword } = useRequestResetPassword();
  const { mutateAsync: handleResendVerificationEmail } = useResendVerificationEmail();

  const value = useMemo(
    () => ({
      isAuthenticated: !!userId,
      userId,
      logout,
      login,
      register,
      requestResetPassword: handleRequestResetPassword,
      resendVerificationEmail: handleResendVerificationEmail,
      initialUserProfile: initialUserProfile || undefined,
    }),
    [userId, logout, login, register, handleRequestResetPassword, handleResendVerificationEmail, initialUserProfile],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return authContext;
}
