import { blastApi } from "../../blastApi";
import { UserProfileSchema } from "../schemas";

export const getUserProfile = async (userId?: string, accessToken?: string) => {
  if (!userId) return null;

  const { data, status } = await blastApi.get<unknown>(`/v1/users/${userId}/profile`, {
    validateStatus: (status) => {
      return status === 404 || status === 401 || status === 200;
    },
    headers: accessToken ? { Authorization: `Bearer ${accessToken}` } : {},
  });
  if (status === 404 || status === 401) return null;

  return UserProfileSchema.parse(data);
};
