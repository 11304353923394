import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from "react";
import { useLocation } from "@remix-run/react";

export type AuthModalState =
  | "login"
  | "register"
  | "awaiting-verification-email"
  | "verification-email-resent"
  | "forgot-password"
  | "reset-password-requested"
  | "first-login"
  | "ban";

interface AuthModalProviderValue {
  isModalOpen: boolean;
  openModal: (state?: AuthModalState) => void;
  modalState: AuthModalState;
  closeModal: () => void;
}

const AuthModalContext = createContext<AuthModalProviderValue | null>(null);

export function AuthModalProvider({ children, track }: { children?: ReactNode; track?: (event: string) => void }) {
  const location = useLocation();
  // Get modal state from URL query params - if modal=login or modal=register
  // intended as a quick workaround for modals opened from the Remix site
  const modalParam = new URLSearchParams(location.search).get("modal");
  const modalStateFromParam: AuthModalState | undefined =
    modalParam === "login" ? "login" : modalParam === "register" ? "register" : undefined;

  const [isModalOpen, setIsModalOpen] = useState(!!modalStateFromParam);
  const [modalState, setModalState] = useState<AuthModalState>(modalStateFromParam ?? "login");

  const openModal = useCallback((state: AuthModalState = "login") => {
    if (state === "register") {
      track?.("Register Button Clicked");
    }
    if (state === "first-login") {
      track?.("User Email Verified");
    }
    setModalState(state);
    setIsModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const providerValue = useMemo(() => {
    return {
      isModalOpen,
      openModal,
      closeModal,
      modalState,
    };
  }, [closeModal, isModalOpen, modalState, openModal]);

  return <AuthModalContext.Provider value={providerValue}>{children}</AuthModalContext.Provider>;
}

export const useAuthModal = () => {
  const authModal = useContext(AuthModalContext);
  if (!authModal) {
    throw new Error("useAuthModal must be used within AuthModalProvider");
  }
  return authModal;
};
